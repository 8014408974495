import classNames from 'classnames'

import Card from 'components/Card'
import { FormattedNumber } from 'components/FormattedNumber'
import Loading from 'components/Loading'
import TitleAndSubCell from 'components/TitleAndSubCell'
import { MARS } from 'constants/assets'

const cardClasses = 'w-full px-2 py-6 text-center gradient-card-content'
const loaderClasses = 'w-20 h-6 mx-auto mb-1'

interface Props {
  position: PositionResponse
  config: ConfigResponse
  isLoading: boolean
}

export default function Metrics(props: Props) {
  const { position, isLoading } = props

  const allocatioNData = [
    {
      title: (
        <FormattedNumber
          className='text-xl'
          amount={Number(position.total)}
          options={{ abbreviated: true, decimals: MARS.decimals }}
        />
      ),
      sub: 'Total Allocation',
    },
    {
      title: (
        <FormattedNumber
          className='text-xl'
          amount={Number(position.vested)}
          options={{ abbreviated: true, decimals: MARS.decimals }}
        />
      ),
      sub: 'Vested',
    },
    {
      title: (
        <FormattedNumber
          className='text-xl'
          amount={Number(position.unlocked)}
          options={{ abbreviated: true, decimals: MARS.decimals }}
        />
      ),
      sub: 'Unlocked',
    },
    {
      title: (
        <FormattedNumber
          className='text-xl'
          amount={Number(position.withdrawable)}
          options={{ abbreviated: true, decimals: MARS.decimals }}
        />
      ),
      sub: 'Withdrawable',
    },
    {
      title: (
        <FormattedNumber
          className='text-xl'
          amount={Number(position.withdrawn)}
          options={{ abbreviated: true, decimals: MARS.decimals }}
        />
      ),
      sub: 'Withdrawn',
    },
  ]

  return (
    <div className='grid w-full grid-cols-2 gap-6 md:grid-cols-5'>
      {allocatioNData.map((data, index) => (
        <Card
          key={index}
          className={classNames(cardClasses, index === 0 && 'col-span-2 md:col-span-1')}
        >
          <TitleAndSubCell
            title={isLoading ? <Loading className={loaderClasses} /> : data.title}
            sub={data.sub}
          />
        </Card>
      ))}
    </div>
  )
}
