import BigNumber from 'bignumber.js'
import moment from 'moment'
import { useCallback } from 'react'

import Button from 'components/Button'
import Card from 'components/Card'
import Text from 'components/Text'
import WalletConnectButton from 'components/Wallet/WalletConnectButton'
import { MARS } from 'constants/assets'
import useToggle from 'hooks/useToggle'
import useStore from 'store'
import { formatValue } from 'utils/formatters'

interface Props {
  position: PositionResponse
  config: ConfigResponse
}

export default function ClaimVesting(props: Props) {
  const walletAddress = useStore((s) => s.address)
  const { position, config } = props
  const {
    start_time: vestingStartTime,
    duration: vestingDuration,
    cliff: vestingCliff,
  } = position.vest_schedule

  const {
    start_time: unlockStartTime,
    duration: unlockDuration,
    cliff: unlockCliff,
  } = config.unlock_schedule

  const realVestingStartTime = vestingStartTime === 0 ? unlockStartTime : vestingStartTime

  const vestingStart = moment.unix(
    new BigNumber(realVestingStartTime).plus(vestingCliff).toNumber(),
  )
  const vestingEnd = moment.unix(
    new BigNumber(realVestingStartTime).plus(vestingDuration).toNumber(),
  )
  const isVestingOver = moment().isAfter(vestingEnd)

  const positionUnlockStartTime =
    unlockStartTime < realVestingStartTime ? realVestingStartTime : unlockStartTime

  const unlockStart = moment.unix(new BigNumber(positionUnlockStartTime).toNumber())
  const unlockEnabled = moment
    .unix(new BigNumber(unlockStartTime).toNumber())
    .add(unlockCliff, 'seconds')
  const unlockEnd = moment.unix(new BigNumber(unlockStartTime).plus(unlockDuration).toNumber())
  const isUnlockedStarted = moment().isAfter(unlockStart)
  const isUnlockEnabled = moment().isAfter(unlockEnabled)
  const isUnlockedComplete = moment().isAfter(unlockEnd)
  const [isWithdrawing, setIsWithdrawing] = useToggle(false)

  const withdraw = useStore((s) => s.withdraw)

  const handleClick = useCallback(async () => {
    setIsWithdrawing(true)
    await withdraw()
    setIsWithdrawing(false)
  }, [withdraw, setIsWithdrawing])

  return (
    <Card
      title='Withdraw unlocked Tokens'
      contentClassName='p-4'
      className='w-full gradient-card-content'
    >
      <Text size='sm' className='mb-2 text-white'>
        The allocation has started its vesting period on{' '}
        {moment.unix(realVestingStartTime).format('MMMM Do YYYY')}{' '}
        <span className='text-white/60'>
          ({moment().diff(moment.unix(realVestingStartTime), 'days')} days ago)
        </span>
        .
      </Text>
      {isVestingOver ? (
        <Text size='sm' className='mb-2 text-white'>
          The vesting period of {vestingEnd.diff(vestingStart, 'days')} days has ended.
        </Text>
      ) : (
        <Text size='sm' className='mb-2 text-white'>
          The vesting period will end on {vestingEnd.format('MMMM Do YYYY')}{' '}
          <span className='text-white/60'>(in {vestingEnd.diff(moment(), 'days')} days)</span>.
        </Text>
      )}

      {isUnlockedStarted ? (
        <Text size='sm' className='mb-2 text-white'>
          The vested tokens started unlocking on {unlockStart.format('MMMM Do YYYY')}{' '}
          <span className='text-white/60'>({moment().diff(unlockStart, 'days')} days ago)</span>.
        </Text>
      ) : (
        <Text size='sm' className='mb-2 text-white'>
          The vested tokens will start unlocking on {unlockStart.format('MMMM Do YYYY')}{' '}
          <span className='text-white/60'>(in {unlockStart.diff(moment(), 'days')} days)</span>.
        </Text>
      )}

      {isUnlockEnabled ? (
        <Text size='sm' className='mb-2 text-white'>
          Claiming is enabled since {unlockEnabled.format('MMMM Do YYYY')}{' '}
          <span className='text-white/60'>({moment().diff(unlockEnabled, 'days')} days ago)</span>.
        </Text>
      ) : (
        <Text size='sm' className='mb-2 text-white'>
          Claiming will be enabled on {unlockEnabled.format('MMMM Do YYYY')}{' '}
          <span className='text-white/60'>
            (in {moment.duration(unlockEnabled.diff(moment())).asHours().toFixed(0)} hours and{' '}
            {Number(moment.duration(unlockEnabled.diff(moment())).asMinutes().toFixed(0)) % 60}{' '}
            minutes)
          </span>
          .
        </Text>
      )}

      {isUnlockedComplete ? (
        <Text size='sm' className='mb-2 text-white'>
          The allocation is completely unlocked.
        </Text>
      ) : (
        <Text size='sm' className='mb-2 text-white'>
          The unlocking period will end on {unlockEnd.format('MMMM Do YYYY')}{' '}
          <span className='text-white/60'>(in {unlockEnd.diff(moment(), 'days')} days)</span>.
        </Text>
      )}
      {walletAddress ? (
        <>
          {position.unlocked === '0' ? (
            <Button
              className='mt-6'
              disabled
              text={`Withdraw is available in ${moment
                .duration(unlockEnabled.diff(moment()))
                .asHours()
                .toFixed(0)} hours and 
              ${Number(moment.duration(unlockEnabled.diff(moment())).asMinutes().toFixed(0)) % 60} 
              minutes`}
              color='tertiary'
            />
          ) : (
            <Button
              className='mt-6 min-w-92.5'
              disabled={position.withdrawable === '0'}
              text={`Withdraw ${formatValue(position.withdrawable, {
                decimals: MARS.decimals,
                suffix: ` ${MARS.symbol}`,
              })} now`}
              color='tertiary'
              onClick={handleClick}
              showProgressIndicator={isWithdrawing}
            />
          )}
        </>
      ) : (
        <WalletConnectButton className='mt-6' textOverride='Connect Wallet' />
      )}
    </Card>
  )
}
