import useSWR from 'swr'

import getConfig from 'api/vesting/getConfig'

export default function useConfig() {
  return useSWR('vestingConfig', () => getConfig(), {
    suspense: true,
    revalidateOnFocus: false,
  })
}
