import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Loading from 'components/Loading'
import NotificationBanner from 'components/NotificationBanner'
import ClaimVesting from 'components/Vesting/ClaimVesting'
import Metrics from 'components/Vesting/Metrics'
import StatusNotification from 'components/Vesting/StatusNotification'
import VestingChart from 'components/VestingChart'
import useConfig from 'hooks/useConfig'
import usePosition from 'hooks/usePosition'
import { convertAllPositionsToData } from 'utils/positions'

export default function Vesting() {
  const { address } = useParams()
  const { data: position, isLoading: isLoadingPosition } = usePosition(address)
  const { data: config, isLoading: isLoadingConfig } = useConfig()
  const [positionsData, setPositionsData] = useState<PositionsData>({
    totalVested: 0,
    totalClaimed: 0,
    totalUnlocked: 0,
  })

  useEffect(() => {
    if (positionsData.chartData) return
    if (isLoadingPosition || isLoadingConfig) return

    const newData = convertAllPositionsToData([position], config)
    setPositionsData(newData)
  }, [position, isLoadingPosition, config, isLoadingConfig, positionsData.chartData])

  if (!address) return null

  if (isLoadingPosition) return <Loading className='w-full h-4' />

  if (position.total === '0')
    return <NotificationBanner type='error' text='This address has no vested allocation' />

  return (
    <>
      <StatusNotification config={config} position={position} />
      <Metrics
        position={position}
        config={config}
        isLoading={isLoadingPosition || isLoadingConfig}
      />
      <VestingChart data={positionsData} title='Allocation Unlock Schedule' />
      <ClaimVesting position={position} config={config} />
    </>
  )
}
