import { useParams } from 'react-router-dom'

import Intro from 'components/Intro'
import WalletConnectButton from 'components/Wallet/WalletConnectButton'
import useStore from 'store'

export default function PortfolioIntro() {
  const { address: urlAddress } = useParams()
  const walletAddress = useStore((s) => s.address)

  if (walletAddress === urlAddress)
    return (
      <Intro
        text={
          <>
            This is <span className='text-white'>Your Vesting</span> information page. You can see
            all vested and unlocked tokens and claim them here.
          </>
        }
        bg='vesting'
      />
    )

  if (urlAddress)
    return (
      <Intro
        text={
          <>
            This is the <span className='text-white'>Vesting</span> information of the address{' '}
            <span className='inline-block w-full h-6 overflow-hidden text-white text-ellipsis'>
              {urlAddress}
            </span>
            You can see all vested, unlocked and claimed tokens of this address here.
          </>
        }
        bg='vesting'
      />
    )

  return (
    <Intro
      text={
        <>
          This is the <span className='text-white'>Vesting Status</span> information page. Connect
          your wallet to see your vesting status.
        </>
      }
      bg='vesting'
    >
      <WalletConnectButton />
    </Intro>
  )
}
