import { CosmWasmClient } from '@cosmjs/cosmwasm-stargate'

import { ENV } from 'constants/env'
import { MarsVestingQueryClient } from 'types/generated/MarsVesting.client'

let _cosmWasmClient: CosmWasmClient
let _marsVestingQueryClient: MarsVestingQueryClient

const getClient = async () => {
  try {
    if (!_cosmWasmClient) {
      _cosmWasmClient = await CosmWasmClient.connect(ENV.CHAIN.rpc)
    }

    return _cosmWasmClient
  } catch (error) {
    throw error
  }
}

const getMarsVestingQueryClient = async () => {
  try {
    if (!_marsVestingQueryClient) {
      const client = await getClient()
      _marsVestingQueryClient = new MarsVestingQueryClient(client, ENV.ADDRESS_VESTING)
    }

    return _marsVestingQueryClient
  } catch (error) {
    throw error
  }
}

export { getClient, getMarsVestingQueryClient }
