import { ReactNode } from 'react'

import Card from 'components/Card'
import { GridGlobe, GridTire } from 'components/Icons'
import Text from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { DEFAULT_SETTINGS } from 'constants/defaultSettings'
import { TUTORIAL_KEY } from 'constants/localStore'
import useLocalStorage from 'hooks/useLocalStorage'

interface Props {
  text: string | ReactNode
  children?: ReactNode
  bg: 'overview' | 'vesting'
}

function IntroBackground(props: { bg: Props['bg'] }) {
  switch (props.bg) {
    case 'vesting':
      return (
        <div className='absolute right-0 top-0 block w-[720px]'>
          <GridTire />
        </div>
      )
    default:
      return (
        <div className='absolute right-0 bottom-0 block w-3/4 md:w-[600px]'>
          <GridGlobe />
        </div>
      )
  }
}

export default function Intro(props: Props) {
  const [tutorial] = useLocalStorage<boolean>(TUTORIAL_KEY, DEFAULT_SETTINGS.tutorial)

  if (!tutorial) return null
  return (
    <Card
      className={`relative w-full p-8 bg-orb-primary/5 bg-cover h-65`}
      contentClassName='flex w-full h-full flex-col justify-between'
    >
      <div className='absolute inset-0 w-full h-full overflow-hidden text-white opacity-10'>
        <IntroBackground bg={props.bg} />
      </div>
      <Tooltip
        className='absolute top-4 right-4'
        type='info'
        content={
          <Text size='xs'>
            If you want to hide these info boxes. Set the <strong>Tutorial</strong> to OFF in the
            Settings.
          </Text>
        }
      />
      <div className='flex w-full'>
        <Text size='lg' className='max-w-full leading-7 w-140 text-white/60'>
          {props.text}
        </Text>
      </div>
      {props.children && <div className='flex w-full gap-4'>{props.children}</div>}
    </Card>
  )
}
