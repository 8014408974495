import useSWR from 'swr'

import getPosition from 'api/vesting/getPosition'

export default function usePosition(address?: string) {
  return useSWR(`position${address}`, () => getPosition(address), {
    suspense: true,
    revalidateOnFocus: false,
  })
}
