import BigNumber from 'bignumber.js'
import moment from 'moment'
import { useMemo } from 'react'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import Card from 'components/Card'
import { FormattedNumber } from 'components/FormattedNumber'
import Loading from 'components/Loading'
import Text from 'components/Text'
import { MARS } from 'constants/assets'
import { DEFAULT_SETTINGS } from 'constants/defaultSettings'
import { REDUCE_MOTION_KEY } from 'constants/localStore'
import useLocalStorage from 'hooks/useLocalStorage'
import { formatValue } from 'utils/formatters'

interface Props {
  data: PositionsData
  title: string
}

function CustomTooltip(props: { payload?: any; label?: string; claimed: number }) {
  const { payload, label, claimed } = props
  if (!payload?.length) return null

  const unlocked = Number(payload[0].value) ?? 0
  const vested = Number(payload[1].value) ?? 0

  return (
    <div className='max-w-[320px] rounded-lg px-4 py-2 shadow-Tooltip gradient-tooltip'>
      <Text size='xs'>{label}</Text>
      <FormattedNumber
        className='text-xs'
        amount={unlocked}
        options={{
          minDecimals: 0,
          maxDecimals: 0,
          prefix: 'Unlocked: ',
          suffix: ` ${MARS.symbol}`,
          decimals: MARS.decimals,
        }}
      />
      <FormattedNumber
        className='text-xs'
        amount={vested}
        options={{
          minDecimals: 0,
          maxDecimals: 0,
          prefix: 'Vested: ',
          suffix: ` ${MARS.symbol}`,
          decimals: MARS.decimals,
        }}
      />
      <FormattedNumber
        className='text-xs'
        amount={claimed}
        options={{
          minDecimals: 0,
          maxDecimals: 0,
          prefix: 'Claimed: ',
          suffix: ` ${MARS.symbol}`,
          decimals: MARS.decimals,
        }}
      />
    </div>
  )
}

export default function VestingChart({ data, title }: Props) {
  const [reduceMotion] = useLocalStorage<boolean>(REDUCE_MOTION_KEY, DEFAULT_SETTINGS.reduceMotion)
  const totalVested = useMemo(() => new BigNumber(data.totalVested), [data.totalVested])
  const totalVestedFifth = totalVested.dividedBy(5)

  const gridYLines = useMemo(
    () => [
      totalVestedFifth.times(1).toNumber(),
      totalVestedFifth.times(2).toNumber(),
      totalVestedFifth.times(3).toNumber(),
      totalVestedFifth.times(4).toNumber(),
      totalVested.toNumber(),
    ],
    [totalVestedFifth, totalVested],
  )

  const gridXLines = [
    '2022-01-01',
    '2023-01-01',
    '2024-01-01',
    '2025-01-01',
    '2026-01-01',
    '2027-01-01',
  ]
  let currentDay: DateVestedUnlockedData | undefined

  if (data.totalVested === 0 || !data.chartData) return <Loading className='w-full h-[496px]' />

  data.chartData.forEach((dataPair) => {
    if (moment(dataPair.date).isBefore(moment()) || moment(dataPair.date).isSame(moment())) {
      currentDay = dataPair
    }

    if (moment(dataPair.date).isAfter(moment())) return
  })

  return (
    <Card
      className='flex flex-wrap w-full overflow-hidden gradient-card-content'
      contentClassName='px-4 pb-4 pt-8'
      title={
        <div className='flex flex-wrap items-center justify-end w-full gap-4 px-4 py-2 font-semibold bg-white/10'>
          <Text size='lg' className='w-full md:flex-grow md:w-auto'>
            {title}
          </Text>
          <div className='flex flex-wrap justify-end w-full max-w-90'>
            <div className='flex flex-wrap justify-end w-1/3'>
              <Text size='2xs' className='w-full text-white/60 text-end md:text-xs'>
                Total Vested
              </Text>
              <FormattedNumber
                className='w-full md:text-xs text-2xs text-end'
                amount={totalVested.toNumber()}
                options={{ decimals: MARS.decimals, suffix: ` ${MARS.symbol}`, abbreviated: true }}
              />
            </div>
            <div className='flex flex-wrap justify-end w-1/3'>
              <Text size='2xs' className='w-full text-orb-primary/60 text-end md:text-xs'>
                Total Unlocked
              </Text>
              <FormattedNumber
                className='w-full md:text-xs text-2xs text-end text-orb-primary'
                amount={data.totalUnlocked}
                options={{ decimals: MARS.decimals, suffix: ` ${MARS.symbol}`, abbreviated: true }}
              />
            </div>
            <div className='flex flex-wrap justify-end w-1/3'>
              <Text size='2xs' className='w-full text-martian-red/60 text-end md:text-xs'>
                Total Claimed
              </Text>
              <FormattedNumber
                className='w-full md:text-xs text-2xs text-end text-martian-red'
                amount={data.totalClaimed}
                options={{
                  decimals: MARS.decimals,
                  suffix: ` ${MARS.symbol}`,
                  abbreviated: true,
                }}
              />
            </div>
          </div>
        </div>
      }
    >
      <div className='h-[400px] w-full'>
        <ResponsiveContainer width='100%' height='100%'>
          <AreaChart
            data={data.chartData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id='unlocked' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='0%' stopColor={'#7230d6'} stopOpacity={0.2} />
                <stop offset='100%' stopColor={'#7230d6'} stopOpacity={0.02} />
              </linearGradient>
              <linearGradient id='vested' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='0%' stopColor={'#FFFFFF'} stopOpacity={0.2} />
                <stop offset='100%' stopColor={'#FFFFFF'} stopOpacity={0.02} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray='10000000 10000000'
              stroke='rgba(255,255,255,0.1)'
              vertical={false}
            />
            <ReferenceLine
              x={moment(currentDay?.date).format('YYYY-MM-DD')}
              stroke='rgba(255, 255, 255, 0.6)'
              strokeDasharray='3 3'
            />
            {data.totalClaimed > 0 && (
              <ReferenceLine y={data.totalClaimed} stroke='#a03b45' strokeDasharray='3 3' />
            )}
            <XAxis
              stroke='rgba(255, 255, 255, 0.6)'
              ticks={gridXLines}
              tickFormatter={(value) => {
                return moment(value).format('YYYY')
              }}
              tickLine={false}
              fontSize={10.53}
              dataKey='date'
            />
            <YAxis
              ticks={gridYLines}
              tickFormatter={(value) => {
                return formatValue(value, { abbreviated: true, decimals: MARS.decimals })
              }}
              tickLine={false}
              fontSize={10.53}
              stroke='rgba(255, 255, 255, 0.6)'
            />
            <Tooltip content={<CustomTooltip claimed={data.totalClaimed} />} />
            <Area
              type='monotone'
              dataKey='unlocked'
              stroke='#7230d6'
              fill='url(#unlocked)'
              isAnimationActive={!reduceMotion}
            />
            <Area
              type='monotone'
              dataKey='vested'
              stroke='#FFFFFF'
              fill='url(#vested)'
              isAnimationActive={!reduceMotion}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Card>
  )
}
