import { getMarsVestingQueryClient } from 'api/cosmwasm.client'
import { ENV } from 'constants/env'

export default async function getConfig(): Promise<ConfigResponse> {
  try {
    const marsVestingQueryClient = await getMarsVestingQueryClient()

    const config: ConfigResponse = await marsVestingQueryClient.config()

    if (config) {
      return config
    }

    return new Promise((_, reject) => reject('No account found'))
  } catch (e) {
    return {
      owner: ENV.ADDRESS_VESTING,
      unlock_schedule: {
        cliff: 0,
        duration: 0,
        start_time: 0,
      },
    }
  }
}
