import moment from 'moment'
import BigNumber from 'bignumber.js'

import NotificationBanner from 'components/NotificationBanner'
import { MARS } from 'constants/assets'
import { formatValue } from 'utils/formatters'

interface Props {
  position: PositionResponse
  config: ConfigResponse
}

export default function StatusNotification(props: Props) {
  const { position, config } = props

  function getStatusText() {
    const {
      start_time: vestingStartTime,
      duration: vestingDuration,
      cliff: vestingCliff,
    } = position.vest_schedule

    const {
      start_time: unlockStartTime,
      duration: unlockDuration,
      cliff: unlockCliff,
    } = config.unlock_schedule

    const realVestingStartTime = vestingStartTime === 0 ? unlockStartTime : vestingStartTime

    const positionUnlockStartTime =
      unlockStartTime < realVestingStartTime ? realVestingStartTime : unlockStartTime

    const unlockStart = moment.unix(new BigNumber(positionUnlockStartTime).toNumber())

    const unlockEnd = moment.unix(new BigNumber(unlockStartTime).plus(unlockDuration).toNumber())
    const allUnlocked =
      position?.vest_schedule.start_time === 0 &&
      position.vest_schedule.duration === 1 &&
      position.vest_schedule.cliff === 0

    if (position.unlocked === '0')
      return `This address has a total allocation of ${formatValue(position.total, {
        decimals: MARS.decimals,
        suffix: ` ${MARS.symbol}`,
      })} which is currently locked until ${moment(unlockStart).format('MMMM Do YYYY')}`

    if (position.unlocked === position.total || allUnlocked) {
      return `This address has a total unlocked allocation of ${formatValue(position.total, {
        decimals: MARS.decimals,
        suffix: ` ${MARS.symbol}`,
      })}`
    }

    return `This address has a total allocation of ${formatValue(position.total, {
      decimals: MARS.decimals,
      suffix: ` ${MARS.symbol}`,
    })} with currently ${formatValue(position.unlocked, {
      decimals: MARS.decimals,
      suffix: ` ${MARS.symbol}`,
    })} unlocked tokens. And a linear unlock until ${moment(unlockEnd).format('MMMM Do YYYY')}`
  }

  return (
    <NotificationBanner
      type={position.unlocked === '0' ? 'info' : 'success'}
      text={getStatusText()}
    />
  )
}
