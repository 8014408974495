import Vesting from 'components/Vesting'
import VestingIntro from 'components/Vesting/VestingIntro'

export default function VestingPage() {
  return (
    <>
      <VestingIntro />
      <Vesting />
    </>
  )
}
