// @ts-nocheck
/**
 * This file was automatically generated by @cosmwasm/ts-codegen@0.19.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { Coin, StdFee } from '@cosmjs/amino'
import { CosmWasmClient, ExecuteResult, SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate'

export class MarsVestingQueryClient implements MarsVestingReadOnlyInterface {
  client: CosmWasmClient
  contractAddress: string

  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client
    this.contractAddress = contractAddress
    this.config = this.config.bind(this)
    this.votingPower = this.votingPower.bind(this)
    this.votingPowers = this.votingPowers.bind(this)
    this.position = this.position.bind(this)
    this.positions = this.positions.bind(this)
  }

  config = async (): Promise<InstantiateMsg> => {
    return this.client.queryContractSmart(this.contractAddress, {
      config: {},
    })
  }

  votingPower = async ({ user }: { user: string }): Promise<VotingPowerResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      voting_power: {
        user,
      },
    })
  }

  votingPowers = async ({
    limit,
    startAfter,
  }: {
    limit?: number
    startAfter?: string
  }): Promise<ArrayOfVotingPowerResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      voting_powers: {
        limit,
        start_after: startAfter,
      },
    })
  }

  position = async ({ user }: { user: string }): Promise<PositionResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      position: {
        user,
      },
    })
  }

  positions = async ({
    limit,
    startAfter,
  }: {
    limit?: number
    startAfter?: string
  }): Promise<ArrayOfPositionResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      positions: {
        limit,
        start_after: startAfter,
      },
    })
  }
}

export class MarsVestingClient extends MarsVestingQueryClient implements MarsVestingInterface {
  client: SigningCosmWasmClient
  sender: string
  contractAddress: string

  constructor(client: SigningCosmWasmClient, sender: string, contractAddress: string) {
    super(client, contractAddress)
    this.client = client
    this.sender = sender
    this.contractAddress = contractAddress
    this.createPosition = this.createPosition.bind(this)
    this.terminatePosition = this.terminatePosition.bind(this)
    this.withdraw = this.withdraw.bind(this)
    this.transferOwnership = this.transferOwnership.bind(this)
  }

  createPosition = async (
    {
      user,
      vestSchedule,
    }: {
      user: string
      vestSchedule: Schedule
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        create_position: {
          user,
          vest_schedule: vestSchedule,
        },
      },
      fee,
      memo,
      funds,
    )
  }

  terminatePosition = async (
    {
      user,
    }: {
      user: string
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        terminate_position: {
          user,
        },
      },
      fee,
      memo,
      funds,
    )
  }

  withdraw = async (
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        withdraw: {},
      },
      fee,
      memo,
      funds,
    )
  }

  transferOwnership = async (
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    funds?: Coin[],
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        transfer_ownership: {},
      },
      fee,
      memo,
      funds,
    )
  }
}
