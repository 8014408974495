import BigNumber from 'bignumber.js'
import moment from 'moment'

export function convertAllPositionsToData(
  allPositions: ArrayOfPositionResponse,
  config: ConfigResponse,
): PositionsData {
  let totalUnlocked = 0
  let totalVested = 0
  let totalClaimed = 0

  const secondsPerDay = 86400
  const unlockStart = config.unlock_schedule.start_time
  const unlockEnable = unlockStart + config.unlock_schedule.cliff

  const chartEnd = unlockStart + config.unlock_schedule.duration + 365 * secondsPerDay
  const chartStart = moment('2021-01-01').add(1, 'hour').unix()

  const chartData: DateVestedUnlockedData[] = []
  for (let time = chartStart; time < chartEnd; time += secondsPerDay) {
    chartData.push({
      date: moment.unix(time).format('YYYY-MM-DD'),
      unlocked: 0,
      vested: 0,
    })
  }

  allPositions.forEach((position) => {
    const unlockingStartTime = moment.unix(unlockStart)
    const positionAllocation = new BigNumber(position.total)
    const vestingDays = new BigNumber(
      position.vest_schedule.duration === 0 ? 1 : position.vest_schedule.duration / 86400,
    )
      .integerValue()
      .toNumber()

    const unlockEnd = moment.unix(
      new BigNumber(config.unlock_schedule.start_time)
        .plus(config.unlock_schedule.duration)
        .toNumber(),
    )

    const unlockingDays = unlockEnd.diff(unlockingStartTime, 'days')

    const vestingStart =
      position.vest_schedule.start_time === 0 ? 1614556800 : position.vest_schedule.start_time
    const vestingCliff = vestingStart + position.vest_schedule.cliff

    const vestingAmountPerDay = positionAllocation.dividedBy(vestingDays === 0 ? 1 : vestingDays)
    const unlockingAmountPerDay = positionAllocation.dividedBy(
      unlockingDays === 0 ? 1 : unlockingDays,
    )

    let vestedDaysSpent = 0
    let vestedAmount = new BigNumber(0)
    let unlockedDaysSpent = 0
    let unlockedAmount = new BigNumber(0)
    chartData.forEach((dataPoint, index) => {
      const currentIndexUnlocked = new BigNumber(chartData[index].unlocked)
      const currentIndexVested = new BigNumber(chartData[index].vested)
      if (
        vestingDays >= vestedDaysSpent &&
        !moment(dataPoint.date).isBefore(moment.unix(vestingStart - secondsPerDay))
      ) {
        vestedDaysSpent++
        vestedAmount = vestingAmountPerDay.times(vestedDaysSpent)
        if (vestedAmount.isGreaterThan(positionAllocation)) {
          vestedAmount = positionAllocation
        }
      }

      if (!moment(dataPoint.date).isBefore(moment.unix(vestingCliff - secondsPerDay))) {
        chartData[index].vested = currentIndexVested.plus(vestedAmount).toNumber()
      }

      if (
        unlockingDays >= unlockedDaysSpent &&
        !moment(dataPoint.date).isBefore(moment.unix(unlockStart - secondsPerDay))
      ) {
        unlockedDaysSpent++
        unlockedAmount = unlockingAmountPerDay.times(unlockedDaysSpent)
        if (unlockedAmount.isGreaterThan(positionAllocation)) {
          unlockedAmount = positionAllocation
        }
      }

      if (moment(dataPoint.date).isBefore(moment.unix(unlockEnable - secondsPerDay))) return
      chartData[index].unlocked = currentIndexUnlocked.plus(unlockedAmount).toNumber()

      chartData[index].unlocked = Math.min(chartData[index].unlocked, chartData[index].vested)
    })
    totalUnlocked += Number(position.unlocked)
    totalVested += Number(position.total)
    totalClaimed += Number(position.withdrawn)
  })

  return {
    totalUnlocked,
    totalVested,
    totalClaimed,
    chartData,
  }
}
