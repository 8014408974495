import { getMarsVestingQueryClient } from 'api/cosmwasm.client'

export default async function getPosition(address?: string): Promise<PositionResponse> {
  const emptyPosition = {
    total: '0',
    unlocked: '0',
    user: '',
    vest_schedule: {
      cliff: 0,
      duration: 0,
      start_time: 0,
    },
    vested: '0',
    withdrawable: '0',
    withdrawn: '0',
  }

  if (!address) return emptyPosition

  try {
    const marsVestingQueryClient = await getMarsVestingQueryClient()

    const userPosition: PositionResponse = await marsVestingQueryClient.position({ user: address })

    if (userPosition) {
      return userPosition
    }

    return new Promise((_, reject) => reject('No account found'))
  } catch (e) {
    return emptyPosition
  }
}
